var adv = adv || {};

// Showing/hiding main navigation (menu)
//-----------------------------------------------------------------
adv.toggleMenu = function() {
    var $toggleButton = $('.js_toggleMenu'),
        $menuWraper = $('.menu'),
        $body = $('body'),
        $html = $('html');

    //check if exists
    if (!$menuWraper.length) {
        return;
    }

    $toggleButton.on('touchstart click', function(e) {
        e.preventDefault();
        if ( $(this).hasClass('active') ) {
            $(this).removeClass('active');
        }
        else {
            $(this).addClass('active');
        }
        $menuWraper.toggleClass('is_open');
        $body.toggleClass('locked');
        $html.toggleClass('locked');
    });

    //add class for debuging
    $menuWraper.addClass('js_toggleMenu_init');

};



// Showing/hiding main navigation (submenu)
//-----------------------------------------------------------------
adv.toggleSubmenu = function() {

    var _vpw = $(document).width(),
        $menuLink = $('.menu__item'),
        _submenuWraper = '.submenu__inner_wrap';

    if ( _vpw > 800 ) {
        $menuLink.hoverIntent(function() {
            if( $(this).find(_submenuWraper).is(':hidden') ) {
                $(this).find(_submenuWraper).addClass('active');
                $(this).find('.menu__link').addClass('active');
            }
            else {
                $(this).find(_submenuWraper).removeClass('active');
                $(this).find('.menu__link').removeClass('active');
            }       
        });

        // //using on mouseenter to prevent duplicate calls
        // $menuLink.mouseenter(function() {
        //     //get the class for color-coding in data-attribute
        //     var _colorCode = $(this).data('colcode');
        //     //remove all the classes...
        //     $('.menu').attr('class',
        //         function(i, c){
        //             return c.replace(/(^|\s)cc-\S+/g, '');
        //     });
        //     //.. and we put the classname back in
        //     $(this).closest('.menu').addClass(_colorCode);
        // });

        // //removes all classes for coloring except the current one
        // $menuLink.mouseleave(function() {
        //     $('.menu').attr('class',
        //         function(i, c){
        //             return c.replace(/(^|\s)cc-\S+/g, '');
        //     });
        // });
        
    }
};


// Showing/hiding main navigation (menu)
//-----------------------------------------------------------------
adv.toggleMobileSubMenu = function() {

    var _vpw = $(document).width(),
        $toggleButton = $('.menu__link--toggle'),
        _submenuWrap = '.submenu__inner_wrap',
        $submenuList = $('.submenu__list'),
        $menuWraper = $('.menu'),
        $body = $('body');

    if ( _vpw < 800 ) {
        $toggleButton.on('touchstart click', function(e) {
            e.preventDefault();
            if( $(this).siblings(_submenuWrap).is(':hidden') ) {
                $(this).addClass('is_open');
                $(this).siblings(_submenuWrap).addClass('is_open');
            }
            else {
                $(this).removeClass('is_open');
                $(this).siblings(_submenuWrap).removeClass('is_open');
            }    
        });
    }

};


$(document).ready(function (){
    adv.toggleMenu();
    adv.toggleSubmenu();

    adv.toggleMobileSubMenu();
});





