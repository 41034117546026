/***************************************************
    Table of contents:

    Functions:
      1. 
      2. 
      3. 
      4. 

    Inits:
      1. initEvents();

    **********************************************/

var adv = adv || {};

adv.click_touchstart = (function(){
    return Modernizr.touchEvents ? 'touchstart' : 'click';
})();



// Counter for characters on message form
//----------------------------------------------------------
adv.textareaCounter = function() {
    var $countTextarea = $('.js_count_textarea');

    if (!$countTextarea.length) {
        return;
    }

    $countTextarea.keyup(function(e) {
        var max = 800;
        var len = $(this).val().length;
        if (len >= max) {
            $('#charNum').text('Dosegnuli ste maksimalni broj znakova!');
            this.value = this.value.substring(0, max);
        } 
        else {
            var char = max - len;
            $('#charNum').text(char + ' znakova preostalo');
        }
    });
};



// Counter for characters on message form
//----------------------------------------------------------
adv.openUserForm = function() {
    var $openButton = $('.js_open_form'),
        $form = $('#form__message_1');

    if (!$openButton.length) {
        return;
    }

    $openButton.on('click', function(e) {
        e.preventDefault();
        $(this).remove();
        $form.removeClass('hidden');
    });
};




$(document).ready( function() {
    adv.textareaCounter();
    adv.openUserForm();

    // Checkbox & Radio buttons replacement
    $('.styled[type=checkbox], .styled[type=radio]').advRadioCheck();


    // Custom dropdown
    $('#cd-dropdown').dropdown({
        speed : 300,
        easing : 'ease',
        gutter : 5,
        // initial stack effect
        stack : false,
        // delay between each option animation
        delay : 0,
        // random angle and positions for the options
        random : false,
        // rotated [right || left || false]: the options will be rotated to the right side or left side
        // make sure to set the transform-origin in the style sheet
        rotated : false,
        // effect to slide in the options
        // value is the margin to start with
        slidingIn : 100
    });


    $("input[type=hidden]").bind("change", function() {
        window.location.href = $("input[name='cd-dropdown']").val();
    });
    
});



$(window).resize(function() {
    if(this.resizeTO) clearTimeout(this.resizeTO);
    this.resizeTO = setTimeout(function() {
        $(this).trigger('resizeEnd');
    }, 300);
});

$(window).bind('resizeEnd', function() {
    //do something, window hasn't changed size in 300ms

    adv.toggleHeader();

});