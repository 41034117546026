var adv = adv || {};


adv.toggleHeader = function() {

    var $header = $('.header'),
        headerHeight = $header.outerHeight(),
        _vpw = $(window).width(),
        lastScrollTop = 0,
        offset = 5,
        didScroll;
        $(window).width();

    $(window).scroll(function(event){
        didScroll = true;
    });

    setInterval(function() {
        if (didScroll) {

            if(_vpw < 800) {
                hasScrolledMobile();
                didScroll = false;
            }
            else {
                hasScrolled();
                didScroll = false;
            }

        }
    }, 200);


    function hasScrolled() {
        var st = $(this).scrollTop();

        if(Math.abs(lastScrollTop - st) <= offset)
            return;

        if (st >= 160 ){
            // Scroll down
            $header.addClass('nav_up');
        } 
        else {
            // Scroll up
            $header.removeClass('nav_up');
        }

        lastScrollTop = st;
    }


    function hasScrolledMobile() {
        var st = $(this).scrollTop();

        if(Math.abs(lastScrollTop - st) <= offset)
            return;

        if (st > lastScrollTop && st > headerHeight){
            // Scroll down
            $header.removeClass('nav_down').addClass('nav_up');
        } else {
            // Scroll up
            if(st + $(window).height() < $(document).height()) {
                $header.removeClass('nav_up').addClass('nav_down');
            }
        }

        lastScrollTop = st;
    }


};



$(document).ready(function (){
    adv.toggleHeader();
}); 
