var adv = adv || {};

// Tabs
//-----------------------------------------------------------------
adv.tabs = function() {

    //Default Action
    $('.tab_content').hide(); //Hide all content
    $('ul.tabs li:first').show(); //Activate first tab
    $('ul.tabs li:first a').addClass('active'); //Activate first tab
    $('.tab_content:first').show(); //Show first tab content
    
    //On Click Event
    $('ul.tabs li').on('touchstart click', function(e) {
        e.preventDefault();
        $('ul.tabs li').removeClass('activated'); //Remove any 'activated' class
        $('ul.tabs li a').removeClass('active'); //Remove any 'active' class
        $(this).addClass('activated'); //Add 'activated' class to selected tab
        $(this).children('a').addClass('active'); //Add 'activated' class to selected tab
        $('.tab_content').hide(); //Hide all tab content
        var activatedTab = $(this).find('a').attr('href'); //Find the rel attribute value to identify the activated tab + content
        $(activatedTab).fadeIn(); //Fade in the activated content
    });
};


$(document).ready(function (){
    adv.tabs();
});