var adv = adv || {};

// Table (responsive)
//-----------------------------------------------------------------
adv.responsiveTable = function() {

    var $tableEl = $('.table--responsive');

    if( !$tableEl.length ) { return; }

    var setContentData = function(el, index) {
        if ( $tableEl.find('th').length ) {
            dataContent = $tableEl.find('th')[index].textContent;
        }
        else {
            dataContent = $tableEl.find('tr:first td')[index].textContent;
        }

        el.attr('data-customtitle', dataContent);
    }

    var init = function(el, index) {
        $tableEl.find('tr').each(function(el, index){
            $(this).find('td').each(function(index){
                setContentData( $(this), index );
            });
        });
    }

    init();


    $('tr[data-href]').on('click', function() {
        document.location = $(this).data('href');
    });
};

$(document).ready(function (){
    adv.responsiveTable();
});