var adv = adv || {};

// Tabs
//-----------------------------------------------------------------
adv.toggleMessages = function() {

    var $toggleBtn = $('.communication__attorney_toggle'),
        $toggleContent = '.communication__attorney_message_wrap';


    $toggleBtn.on('click', function(e) {
        e.preventDefault();

        $(this).find('i').toggleClass('icon-carrot-down icon-carrot-up');

        $(this).parent().siblings($toggleContent).toggleClass('toggle_hidden'); 
    });    



};


$(document).ready(function (){
    adv.toggleMessages();
});