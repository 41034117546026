// jshint multistr: true
var adv = adv || {};



// Form validation(s)
//----------------------------------------------------------
adv.form_validation_contact = function() {
    var $contact_form = $('#contact_form'),
        $register_form = $('#registration_form'),
        $login_form = $('#login_form'),
        $upit_form = $('#upit_form'),
        $odgovor_form = $('.odgovor_form'),
        $reg_form = $('#reg_form'),
        $form = $('.form');

    if (!$form.length) {
        return;
    }

    $odgovor_form.each(function () {
            $(this).validate({
              rules: {
                message: {
                  required: true,
                  minlength: 2
                }
              }
            });
        });

    $contact_form.validate({
      rules: {
        ime: {
          required: true,
          minlength: 3
        },
        message: {
          required: true,
          minlength: 10
        }
      }
    });

    $upit_form.validate({
      rules: {
        message: {
          required: true,
          minlength: 10
        },
        email: {
          required: true,
          email: true
        },
        password: {
          required: true,
          minlength: 6
        },
        mjesto: {
          required: true,
          minlength: 2
        }
      }
    });


    $login_form.validate({
      rules: {
        email: {
          required: true,
          email: true
        },
        password: {
          required: true,
          minlength: 6
        }
      },
      highlight: function(element) {
          $(element).addClass('error').parent().addClass('error');
      },
      unhighlight: function(element) {
          $(element).removeClass('error').parent().removeClass('error');
      }
    });

    $register_form.validate({
      rules: {
        email: {
          required: true,
          email: true
        },
        password: {
          required: true,
          minlength: 6
        },
        confirm_password: {
          required: true,
          minlength: 6,
          equalTo: "#password"
        }
      },
      highlight: function(element) {
          $(element).addClass('error').parent().addClass('error');
      },
      unhighlight: function(element) {
          $(element).removeClass('error').parent().removeClass('error');
      }
    });


    $reg_form.validate({
      rules: {
        name: {
          required: true,
          minlength: 4
        },
        email: {
          required: true,
          email: true
        },
        password: {
          required: true,
          minlength: 6
        },
        confirm_password: {
          required: true,
          minlength: 6,
          equalTo: "#password"
        }
      },
      highlight: function(element) {
          $(element).addClass('error').parent().addClass('error');
      },
      unhighlight: function(element) {
          $(element).removeClass('error').parent().removeClass('error');
      }
    });

};



$(document).ready(function (){
    adv.form_validation_contact();
});